import React, { lazy } from 'react';
import IsRecruiter from './middleware/Recruiter'; 
import IsAdmin from './middleware/Admin';
import RedirectCandidate from './middleware/RedirectCandidate';
import RedirectRecruiter from './middleware/RedirectRecruiter';



const Home = lazy(()=>import("./pages/Home"))
const Error = lazy(()=>import("./pages/Error"))
const JobDetails = lazy(()=>import("./pages/JobDetails"))
const AboutUs = lazy(()=>import("./pages/About"))
const Pricing = lazy(()=>import("./pages/Pricing"))
const Faq = lazy(()=>import("./pages/Faq"))
const ContactUs = lazy(()=>import("./pages/Contact"))
const Blogs = lazy(()=>import("./pages/Blog"))
const BlogsByHashtag = lazy(()=>import("./pages/BlogsByHashtag"))
const BlogDetails = lazy(()=>import("./pages/BlogDetails"))
const Companies = lazy(()=>import("./pages/Companies"))
const CompanyInfo = lazy(()=>import("./pages/CompanyInfo"))
const Jobs = lazy(()=>import("./pages/Jobs"))
const PrivacyPolicy = lazy(()=>import("./pages/PrivacyPolicy"))
const ReturnPolicy = lazy(()=>import("./pages/ReturnPolicy"))
const TermCondition = lazy(()=>import("./pages/TermCondition"))
const AllLocations = lazy(()=>import("./pages/AllLocations"))
const AllDepartment = lazy(()=>import("./pages/Alldepartment"))

const RecruiterRegister = lazy(()=>import("./pages/Recruiter-register"))
const VerifyMail = lazy(()=>import("./pages/VerifyMail"))
const RecruiterLogin = lazy(()=>import("./pages/recruiter/Login"))
const RecruiterForgotPassword = lazy(()=>import("./pages/recruiter/ForgotPassword"));
const RecruiterResetPassword = lazy(()=>import("./pages/recruiter/ResetPassword"));
const RecruiterDashboard = lazy(()=>import("./pages/recruiter/Dashboard"))
const RecruiterManageJob = lazy(()=>import("./pages/recruiter/ManageJobs"))
const RecruiterPostJob = lazy(()=>import("./pages/recruiter/PostJob"))
const RecruiterUpdateJob = lazy(()=>import("./pages/recruiter/UpdateJob"))
const RecruiterManageCandidate = lazy(()=>import("./pages/recruiter/ManageCandidate"))
const RecruiterCandidateDetails = lazy(()=>import("./pages/recruiter/CandidateDetails"))
const RecruiterManageTeam = lazy(()=>import("./pages/recruiter/ManageTeam"))
const RecruiterAddTeam = lazy(()=>import("./pages/recruiter/AddTeam"))
const RecruiterUpdateTeam = lazy(()=>import("./pages/recruiter/UpdateTeam"))
const RecruiterManageResume = lazy(()=>import("./pages/recruiter/ManageResume"))
const RecruiterManageMemberShip = lazy(()=>import("./pages/recruiter/ManageMemberShip"))
const RecruiterBillingReport = lazy(()=>import("./pages/recruiter/BillingReport"))
const RecruiterManageAccount = lazy(()=>import("./pages/recruiter/ManageAccount"))
const RecruiterManageMessage = lazy(()=>import("./pages/recruiter/ManageMassege"))

const RecruiterManageNotification = lazy(()=>import("./pages/recruiter/Notification"))
const ManageJobInfo = lazy(()=>import("./pages/JobInfo"))


const CondidateRegister = lazy(()=>import("./pages/Condidate-register"));
const CondidateMailVerify = lazy(()=>import("./pages/CondidateMailVerify"));
const CondidateLogin = lazy(()=>import("./pages/condidate/Login"));
const CondidateForgotPassword = lazy(()=>import("./pages/condidate/ForgotPassword"));
const CondidateResetPassword = lazy(()=>import("./pages/condidate/ResetPassword"));
const IsCondidate = lazy(()=>import("./middleware/Condidate"));
const CondidateDashboard = lazy(()=>import("./pages/condidate/Dashboard"));
const CondidateAccountSetting = lazy(()=>import("./pages/condidate/AccountSetting"));
const CondidateManageJob = lazy(()=>import("./pages/condidate/ManageJob"));
const CondidateSaveCompanies = lazy(()=>import("./pages/condidate/SaveCompanies"));
const CondidateJobAlert = lazy(()=>import("./pages/condidate/JobAlert"));
const CondidateProfile = lazy(()=>import("./pages/condidate/Profile"));
const CondidateMessage = lazy(()=>import("./pages/condidate/Message"));
const CondidateSaveJob = lazy(()=>import("./pages/condidate/SaveJobs"));


// admin
const AdminLogin = lazy(()=>import("./pages/admin/Login"));
const AdminForgotPassword = lazy(()=>import("./pages/admin/ForgotPassword"));
const AdminResetPassword = lazy(()=>import("./pages/admin/ResetPassword"));
const AdminDashboard = lazy(()=>import("./pages/admin/Dashboard"));
const AdminManageBrand = lazy(()=>import("./pages/admin/ManageBrand"));
const AdminAddBrand = lazy(()=>import("./pages/admin/AddBrand"));
const AdminUpdateBrand = lazy(()=>import("./pages/admin/UpdateBrand"));
//experience Type
const AdminManageExperienceType = lazy(()=>import("./pages/admin/ExperienceType/ManageExperienceType"));
const AdminAddExperienceType = lazy(()=>import("./pages/admin/ExperienceType/AddExperienceType"));
const AdminUpdateExperienceType = lazy(()=>import("./pages/admin/ExperienceType/UpdateExperienceType"));
//experience Type
//category
const AdminManageCategory = lazy(()=>import("./pages/admin/category/ManageCategory"));
const AdminAddCategory = lazy(()=>import("./pages/admin/category/AddCategory"));
const AdminUpdateCategory = lazy(()=>import("./pages/admin/category/UpdateCategory"));
//category
//education Type
const AdminManageEducationType = lazy(()=>import("./pages/admin/EducationType/ManageEducationType"));
const AdminAddEducationType = lazy(()=>import("./pages/admin/EducationType/AddEducationType"));
const AdminUpdateEducationType = lazy(()=>import("./pages/admin/EducationType/UpdateEducationType"));
//education Type
//langugae
const AdminManageLanguage = lazy(()=>import("./pages/admin/Language/ManageLanguage"));
const AdminAddLanguage = lazy(()=>import("./pages/admin/Language/AddLanguage"));
const AdminUpdateLanguage = lazy(()=>import("./pages/admin/Language/UpdateLanguage"));
//langugae
//tags
const AdminManageTags = lazy(()=>import("./pages/admin/tags/ManageTags"));
const AdminAddTags = lazy(()=>import("./pages/admin/tags/AddTags"));
const AdminUpdateTags = lazy(()=>import("./pages/admin/tags/UpdateTags"));
//tags
//industry
const AdminManageIndustry = lazy(()=>import("./pages/admin/Industry/ManageIndustry"));
const AdminAddIndustry = lazy(()=>import("./pages/admin/Industry/AddIndustry"));
const AdminUpdateIndustry = lazy(()=>import("./pages/admin/Industry/UpdateIndustry"));
//industry
//location
const AdminManageLocation = lazy(()=>import("./pages/admin/Location/ManageLocation"));
const AdminAddLocation = lazy(()=>import("./pages/admin/Location/AddLocation"));
const AdminUpdateLocation = lazy(()=>import("./pages/admin/Location/UpdateLocation"));
//location
//blog
const AdminManageBlog = lazy(()=>import("./pages/admin/Blogs/ManageBlog"));
const AdminAddBlog = lazy(()=>import("./pages/admin/Blogs/AddBlog"));
const AdminUpdateBlog = lazy(()=>import("./pages/admin/Blogs/UpdateBlog"));
//blog
//testimonial
const AdminManageTestimonial = lazy(()=>import("./pages/admin/testimonial/ManageTastimonial"));
const AdminAddTestimonial = lazy(()=>import("./pages/admin/testimonial/AddTestimonial"));
const AdminUpdateTestimonial = lazy(()=>import("./pages/admin/testimonial/UpdateTastimonial"));
//testimonial
//manage job
const AdminManageJob = lazy(()=>import("./pages/admin/job/ManageJob"));
const AdminRecentJob = lazy(()=>import("./pages/admin/job/RecentJob"));
const AdminFeaturedJob = lazy(()=>import("./pages/admin/job/FeaturedJobs"));
const AdminJobInfo = lazy(()=>import("./pages/admin/job/JobInfo"));
//manage job
//manage condidate
const AdminRecentCandidate = lazy(()=>import("./pages/admin/candidate/RecentCondidate"));
const AdminManageCandidate = lazy(()=>import("./pages/admin/candidate/ManageCandidate"));
const AdminCandidateInfo = lazy(()=>import("./pages/admin/candidate/CandidateInfo"));
const AdminManageResume = lazy(()=>import("./pages/admin/ManageResume"));
//manage condidate

//manage condidate
const AdminAddSubscription = lazy(()=>import("./pages/admin/subscription/AddsubScription"));
const AdminManageSubscription = lazy(()=>import("./pages/admin/subscription/ManageSubscription"));
const AdminUpdateSubscription = lazy(()=>import("./pages/admin/subscription/UpdateSubscripiton"));
//manage condidate

//manage condidate
const AdminManageSubAdmin = lazy(()=>import("./pages/admin/subadmin/ManageSubAdmin"));
const AdminUpdateSubAdmin = lazy(()=>import("./pages/admin/subadmin/UpdateSubAdmin"));
//manage condidate
//manage recruiter
const AdminManageRecruiter = lazy(()=>import("./pages/admin/recruiter/ManageRecruiter"));
const AdminRecruiterInfo = lazy(()=>import("./pages/admin/recruiter/RecruiterInfo"));
const AdminRecentRecruiter = lazy(()=>import("./pages/admin/recruiter/RecentRecruiter"));
//manage recruiter
const AdminNotification = lazy(()=>import("./pages/admin/Notification"));
const AdminContactquery = lazy(()=>import("./pages/admin/contactQuery/manageContact"));
const AdminAccountSetting = lazy(()=>import("./pages/admin/AccountSetting/AccountSetting"));
// admin

//Seo
const AdminManageSeo = lazy(()=>import("./pages/admin/Seo/ManageSeo"));
const AdminAddSeo = lazy(()=>import("./pages/admin/Seo/AddSeo"));
const AdminUpdateSeo = lazy(()=>import("./pages/admin/Seo/UpdateSeo"));
//Seo

const AdminAccountBillingReport = lazy(()=>import("./pages/admin/BillingReports"));
const AdminMessage = lazy(()=>import("./pages/admin/message/Message"));

// routes  
const routes = [
    { path: "/", element: <Home /> },
    { path: "/job/:slug", element: <JobDetails /> },
    { path: "/about-us", element: <AboutUs /> },
    { path: "/our-plans", element: <Pricing /> },
    { path: "/faqs", element: <Faq /> },
    { path: "/contact-us", element: <ContactUs /> },
    { path: "/blogs", element: <Blogs /> },
    { path: "/blogs/:hashtag", element: <BlogsByHashtag /> },
    { path: "/blog/:slug", element: <BlogDetails /> },
    { path: "/companies", element: <Companies /> },
    { path: "/company-info/:id", element: <CompanyInfo /> },
    { path: "/jobs", element: <Jobs /> },
    { path: "/privacy-policy", element: <PrivacyPolicy /> },
    { path: "/refund-policy", element: <ReturnPolicy /> },
    { path: "/terms-and-condition", element: <TermCondition /> },
    { path: "/job-by-locations", element: <AllLocations /> },
    { path: "/job-by-category", element: <AllDepartment /> },
    { path: "/*", element: <Error /> },
    { path: "/recruiter-register", element: <RedirectRecruiter><RecruiterRegister /></RedirectRecruiter> },
    { path: "/verify/recruiter/mail", element: <VerifyMail /> },
    //admin
    {
      path:"/admn/login",
      element:<AdminLogin/>
    },
    {
      path:"/admn/forgot-password",
      element:<AdminForgotPassword/>
    },
    {
      path:"/admn/password/reset/:token",
      element:<AdminResetPassword/>
    },
    {
      path:"/admn/dashboard",
      element:<IsAdmin><AdminDashboard/></IsAdmin>
    },
    {
      path:"/admn/manage-brand",
      element:<IsAdmin><AdminManageBrand/></IsAdmin>
    },
    {
      path:"/admn/add-brand",
      element:<IsAdmin><AdminAddBrand/></IsAdmin>
    },
    {
      path:"/admn/update-brand/:id",
      element:<IsAdmin><AdminUpdateBrand/></IsAdmin>
    },
    {
      path:"/admn/manage-blog",
      element:<IsAdmin><AdminManageBlog/></IsAdmin>
    },
    {
      path:"/admn/add-blog",
      element:<IsAdmin><AdminAddBlog/></IsAdmin>
    },
    {
      path:"/admn/update-blog/:id",
      element:<IsAdmin><AdminUpdateBlog/></IsAdmin>
    },
    {
      path:"/admn/manage-category",
      element:<IsAdmin><AdminManageCategory/></IsAdmin>
    },
    {
      path:"/admn/notification",
      element:<IsAdmin><AdminNotification/></IsAdmin>
    },
    {
      path:"/admn/add-category",
      element:<IsAdmin><AdminAddCategory/></IsAdmin>
    },
    {
      path:"/admn/update-category/:id",
      element:<IsAdmin><AdminUpdateCategory/></IsAdmin>
    },
    {
      path:"/admn/manage-experience-type",
      element:<IsAdmin><AdminManageExperienceType/></IsAdmin>
    },
    {
      path:"/admn/add-experience-type",
      element:<IsAdmin><AdminAddExperienceType/></IsAdmin>
    },
    {
      path:"/admn/update-experience-type/:id",
      element:<IsAdmin><AdminUpdateExperienceType/></IsAdmin>
    },
    {
      path:"/admn/manage-education-type",
      element:<IsAdmin><AdminManageEducationType/></IsAdmin>
    },
    {
      path:"/admn/add-education-type",
      element:<IsAdmin><AdminAddEducationType/></IsAdmin>
    },
    {
      path:"/admn/update-education-type/:id",
      element:<IsAdmin><AdminUpdateEducationType/></IsAdmin>
    },
    {
      path:"/admn/manage-language",
      element:<IsAdmin><AdminManageLanguage/></IsAdmin>
    },
    {
      path:"/admn/add-language",
      element:<IsAdmin><AdminAddLanguage/></IsAdmin>
    },
    {
      path:"/admn/update-language/:id",
      element:<IsAdmin><AdminUpdateLanguage/></IsAdmin>
    },
    {
      path:"/admn/manage-testimonial",
      element:<IsAdmin><AdminManageTestimonial/></IsAdmin>
    },
    {
      path:"/admn/add-testimonial",
      element:<IsAdmin><AdminAddTestimonial/></IsAdmin>
    },
    {
      path:"/admn/update-testimonial/:id",
      element:<IsAdmin><AdminUpdateTestimonial/></IsAdmin>
    },
   
    {
      path:"/admn/manage-tags",
      element:<IsAdmin><AdminManageTags/></IsAdmin>
    },
    {
      path:"/admn/add-tag",
      element:<IsAdmin><AdminAddTags/></IsAdmin>
    },
    {
      path:"/admn/update-tag/:id",
      element:<IsAdmin><AdminUpdateTags/></IsAdmin>
    },
    {
      path:"/admn/manage-seo",
      element:<IsAdmin><AdminManageSeo/></IsAdmin>
    },
    {
      path:"/admn/add-seo",
      element:<IsAdmin><AdminAddSeo/></IsAdmin>
    },
    {
      path:"/admn/update-seo/:id",
      element:<IsAdmin><AdminUpdateSeo/></IsAdmin>
    },
    {
      path:"/admn/manage-industry",
      element:<IsAdmin><AdminManageIndustry/></IsAdmin>
    },
    {
      path:"/admn/add-industry",
      element:<IsAdmin><AdminAddIndustry/></IsAdmin>
    },
    {
      path:"/admn/update-industry/:id",
      element:<IsAdmin><AdminUpdateIndustry/></IsAdmin>
    },
    {
      path:"/admn/manage-subscription",
      element:<IsAdmin><AdminManageSubscription/></IsAdmin>
    },
    {
      path:"/admn/add-subscription",
      element:<IsAdmin><AdminAddSubscription/></IsAdmin>
    },
    {
      path:"/admn/update-subscription/:id",
      element:<IsAdmin><AdminUpdateSubscription/></IsAdmin>
    },
    {
      path:"/admn/manage-subadmin",
      element:<IsAdmin><AdminManageSubAdmin/></IsAdmin>
    },
    {
      path:"/admn/update-subadmin/:id",
      element:<IsAdmin><AdminUpdateSubAdmin/></IsAdmin>
    },
    {
      path:"/admn/manage-location",
      element:<IsAdmin><AdminManageLocation/></IsAdmin>
    },
    {
      path:"/admn/add-location",
      element:<IsAdmin><AdminAddLocation/></IsAdmin>
    },
    {
      path:"/admn/update-location/:id",
      element:<IsAdmin><AdminUpdateLocation/></IsAdmin>
    },
    {
      path:"/admn/manage-job",
      element:<IsAdmin><AdminManageJob/></IsAdmin>
    },
    {
      path:"/admn/recent-job",
      element:<IsAdmin><AdminRecentJob/></IsAdmin>
    },
    {
      path:"/admn/featured-job",
      element:<IsAdmin><AdminFeaturedJob/></IsAdmin>
    },
    {
      path:"/admn/job-info/:id",
      element:<IsAdmin><AdminJobInfo/></IsAdmin>
    },
    {
      path:"/admn/manage-candidate",
      element:<IsAdmin><AdminManageCandidate/></IsAdmin>
    },
    {
      path:"/admn/recent-candidate",
      element:<IsAdmin><AdminRecentCandidate/></IsAdmin>
    },
    {
      path:"/admn/candidate-info/:id",
      element:<IsAdmin><AdminCandidateInfo/></IsAdmin>
    },
    {
      path:"/admn/manage-resume",
      element:<IsAdmin><AdminManageResume/></IsAdmin>
    },
    {
      path:"/admn/manage-recruiter",
      element:<IsAdmin><AdminManageRecruiter/></IsAdmin>
    },
    {
      path:"/admn/recent-recruiter",
      element:<IsAdmin><AdminRecentRecruiter/></IsAdmin>
    },
    {
      path:"/admn/recruiter-details/:id",
      element:<IsAdmin><AdminRecruiterInfo/></IsAdmin>
    },
    {
      path:"/admn/account-setting",
      element:<IsAdmin><AdminAccountSetting/></IsAdmin>
    },
    {
      path:"/admn/query",
      element:<IsAdmin><AdminContactquery/></IsAdmin>
    },
    {
      path:"/admn/billing-report",
      element:<IsAdmin><AdminAccountBillingReport/></IsAdmin>
    },
    {
      path:"/admn/message/:id",
      element:<IsAdmin><AdminMessage/></IsAdmin>
    },

    //admin
    //recruitet
    {
      path:"/recruiter/login",
      element:<RedirectRecruiter><RecruiterLogin/></RedirectRecruiter>
    },
    {
      path:"/recruiter/forgot-password",
      element:<RecruiterForgotPassword/>
    },
    {
      path:"/recruiter/password/reset/:token",
      element:<RecruiterResetPassword/>
    },
    {
      path:"/recruiter/dashboard",
      element:<IsRecruiter><RecruiterDashboard/></IsRecruiter>
    },
    {
      path:"/recruiter/manage-job",
      element:<IsRecruiter><RecruiterManageJob/></IsRecruiter>
    },
    {
      path:"/recruiter/post-job",
      element:<IsRecruiter><RecruiterPostJob/></IsRecruiter>
    },
    {
      path:"/recruiter/update-job/:id",
      element:<IsRecruiter><RecruiterUpdateJob/></IsRecruiter>
    },
    {
      path:"/recruiter/manage-candidate",
      element:<IsRecruiter><RecruiterManageCandidate/></IsRecruiter>
    },
    {
      path:"/recruiter/candidate-details/:id",
      element:<IsRecruiter><RecruiterCandidateDetails/></IsRecruiter>
    },
    {
      path:"/recruiter/manage-team",
      element:<IsRecruiter><RecruiterManageTeam/></IsRecruiter>
    },
    {
      path:"/recruiter/add-team",
      element:<IsRecruiter><RecruiterAddTeam/></IsRecruiter>
    },
    {
      path:"/recruiter/update-team/:id",
      element:<IsRecruiter><RecruiterUpdateTeam/></IsRecruiter>
    },
    {
      path:"/recruiter/manage-resume",
      element:<IsRecruiter><RecruiterManageResume/></IsRecruiter>
    },
    {
      path:"/recruiter/manage-membership",
      element:<IsRecruiter><RecruiterManageMemberShip/></IsRecruiter>
    },
    {
      path:"/recruiter/billing-report",
      element:<IsRecruiter><RecruiterBillingReport/></IsRecruiter>
    },
    {
      path:"/recruiter/manage-account",
      element:<IsRecruiter><RecruiterManageAccount/></IsRecruiter>
    },
    {
      path:"/recruiter/manage-message/:roomId",
      element:<IsRecruiter><RecruiterManageMessage/></IsRecruiter>
    },
    {
      path:"/recruiter/notification",
      element:<IsRecruiter><RecruiterManageNotification/></IsRecruiter>
    },
    {
      path:"/recruiter/job-info/:id",
      element:<IsRecruiter><ManageJobInfo/></IsRecruiter>
    },
    //recruiter
    // condidate
    {
      path:"/register",
      element:<RedirectCandidate><CondidateRegister/></RedirectCandidate>
    },
    { path: "/verify/condidate/mail", element: <CondidateMailVerify /> },
    {
      path:"/login",
      element:<RedirectCandidate><CondidateLogin/></RedirectCandidate>
    },
    {
      path:"/candidate/forgot-password",
      element:<CondidateForgotPassword/>
    },
    {
      path:"/candidate/password/reset/:token",
      element:<CondidateResetPassword/>
    },
    {
      path:"/candidate/dashboard",
      element:<IsCondidate><CondidateDashboard/></IsCondidate>
    },
    {
      path:"/candidate/account-setting",
      element:<IsCondidate><CondidateAccountSetting/></IsCondidate>
    },
    {
      path:"/candidate/applied-job",
      element:<IsCondidate><CondidateManageJob/></IsCondidate>
    },
    {
      path:"/candidate/save-companies",
      element:<IsCondidate><CondidateSaveCompanies/></IsCondidate>
    },
    {
      path:"/candidate/job-alert",
      element:<IsCondidate><CondidateJobAlert/></IsCondidate>
    },
    {
      path:"/candidate/profile",
      element:<IsCondidate><CondidateProfile/></IsCondidate>
    },
    {
      path:"/candidate/message",
      element:<IsCondidate><CondidateMessage/></IsCondidate>
    },
    {
      path:"/candidate/save-job",
      element:<IsCondidate><CondidateSaveJob/></IsCondidate>
    },
    // condidate
  ];



  export default  routes